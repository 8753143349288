<template>
    <v-container>
        <v-row v-if="!profile_id && !loading && url && code" justify="center" class="my-10">
            <div cols="auto">
                <a :href="url" target="_blank">
                    <qrcode-vue :value="url" :size="size" level="L" class="qrcode"></qrcode-vue>
                </a>
            </div>
        </v-row>
        <v-row v-if="!profile_id && code" no-gutters justify="center" align="center">
            <v-text-field label="認證碼" :value="code" readonly class="display-s pr-2"></v-text-field>
            <v-btn @click="copyCode">copy</v-btn>
        </v-row>
        <v-row v-if="!profile_id && url" no-gutters justify="center" align="center" class="mt-2">
            <v-col justify="center" align="center">
                <div>認證專屬網址：</div>
                <a :href="url" target="_blank">{{ url }}</a>
            </v-col>
        </v-row>
        <v-row v-if="profile_id" no-gutters justify="center" align="center" class="mt-2">
            <v-col justify="center" align="center">
                <div>此身份已綁定LINE，如要解除綁定請聯絡羅氏相關人員。</div>
            </v-col>
        </v-row>
        <v-divider v-if="!loading && isValidate" class="my-3" />
        <v-row v-if="!loading" class="mt-7">
            <v-col>
                <v-container class="pa-0">
                    <v-row v-if="!isValidate" justify="center">
                        <v-col cols="auto"> 無認證資料 </v-col>
                    </v-row>
                    <v-row v-if="ch_name" justify="center">
                        <v-text-field label="中文姓名" :value="ch_name" readonly class="display-m px-3"></v-text-field>
                    </v-row>
                    <v-row v-if="en_name" justify="center">
                        <v-text-field label="英文姓名" :value="en_name" readonly class="display-m px-3"></v-text-field>
                    </v-row>
                    <v-row v-if="email" justify="center">
                        <v-text-field label="Email" :value="email" readonly class="display-m px-3"></v-text-field>
                    </v-row>
                    <v-row v-if="department" justify="center">
                        <v-text-field label="部門" :value="department" readonly class="display-m px-3"></v-text-field>
                    </v-row>
                    <v-row v-if="isValidate" justify="center">
                        <v-text-field label="綁定LINE" :value="profile_id ? '是' : '否'" readonly
                            class="display-m px-3"></v-text-field>
                    </v-row>
                    <v-row v-if="permissions.length" justify="center">
                        <v-combobox :value="permissions" :items="permission" label="癌別" item-text="name" readonly multiple
                            chips class="display-m px-3"></v-combobox>
                    </v-row>
                    <v-row justify="center" class="mt-5">
                        <!-- <v-col cols="auto">
              <v-btn @click="toggleEmailDialog">email</v-btn>
            </v-col> -->
                        <v-col cols="auto">
                            <v-btn @click="logout">登出</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-skeleton-loader v-if="loading" class="mx-auto" max-width="300" type="image,article,actions"></v-skeleton-loader>
        <v-snackbar :top="true" v-model="snackbar" color="success">
            {{ copiedTarget }} 已複製至您的剪貼簿
        </v-snackbar>
    </v-container>
</template>

<script>
import API from '@/models/api';
import { mapState } from 'vuex';
import QrcodeVue from 'qrcode.vue'

const TOKEN_PREFIX = 'roche.qr-code.user';
export default {
    components: {
        QrcodeVue,
    },
    data() {
        return {
            isValidate: [],
            hasEmail: false,
            department: '',
            email: '',
            ch_name: '',
            en_name: '',
            profile_id: null,
            code: '',
            permission: [],
            permissions: [],

            // snackbar
            copiedTarget: '',
            snackbar: false,

            // loading
            loading: true,

            // qr-code
            url: '',
            size: 250,

            // form
            valid: true
        };
    },
    computed: {
        ...mapState({
            verifyUrl: 'verifyUrl'
        })
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            const LOCAL_EMAIL = window.localStorage.getItem(
                `${TOKEN_PREFIX}.email`
            );
            const res = await API.Employee.findEmail(LOCAL_EMAIL);
            this.isValidate = res.data.length;
            this.loading = false;
            if (res.data.length) {
                const DATA = res.data[0];
                this.hasEmail = res.data.length ? '是' : '否';
                this.department =
                    (DATA.department && DATA.department.name) || 'N/A';
                this.email = DATA.email || 'N/A';
                this.ch_name = DATA.ch_name || 'N/A';
                this.en_name = DATA.en_name || 'N/A';
                this.profile_id = DATA.profile_id;
                this.code = DATA.code || 'N/A';
                this.url = `${this.verifyUrl}${this.code}`;
                if (DATA.permissions.length) {
                    this.permission = DATA.permissions;
                    this.permission.forEach(item => {
                        this.permissions.push(item.permission.name);
                    });
                } else {
                    this.permissions.push('N/A');
                }
            } else {
                this.email = LOCAL_EMAIL;
            }
        },
        async logout() {
            try {
                const res = await this.$gAuth.signOut();
                window.localStorage.clear();
                this.$router.go(0);
                return res;
            } catch (e) {
                console.log(e);
            }
        },
        toggleEmailDialog() {
            this.showEmailDialog = !this.showEmailDialog;
        },
        copyCode() {
            this.$copyText(this.code).then(() => {
                this.copiedTarget = '驗證碼';
                this.snackbar = true;
            });
        },
        copyUrl() {
            this.$copyText(this.url).then(() => {
                this.copiedTarget = 'URL';
                this.snackbar = true;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.display {
    &-s {
        max-width: 150px;
    }

    &-m {
        max-width: 370px;
    }

    &-l {
        max-width: 500px;
    }
}
</style>
